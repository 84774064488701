<template>
  <div>
    <a-page-header style="padding: 0" title="Workdays">
      <template #extra>
        <a-popconfirm
          title="Are you sure to re-calculate salaries of this month"
          okText="sure"
          @confirm="reCalculateSalaries"
        >
          <a-button key="1" type="primary">Re-Calculate Salaries</a-button>
        </a-popconfirm>
      </template>
    </a-page-header>
    <a-row class="my-20" justify="space-between" align="middle">
      <a-col>
        <a-button @click="togglemonth(0)"><LeftOutlined /></a-button>
      </a-col>
      <a-col>
        <a-typography-title
          :level="3"
          class="text-center"
          style="margin-bottom: 0"
        >
          {{ $filters.formatTime(currentTime, "YYYY-MM") }}
        </a-typography-title>
      </a-col>
      <a-col>
        <a-button @click="togglemonth(1)"><RightOutlined /></a-button>
      </a-col>
    </a-row>

    <div class="">
      <a-row type="flex" justify="center">
        <a-col :xl="8">
          <div class="d-flex">
            <div
              class="my-10 text-center"
              :span="3"
              :push="3"
              style="width: 14%"
              v-for="(item, index) in week"
              :key="index"
            >
              <a-typography-text
                class="text-center"
                style="font-size: 20px"
                strong
                type="secondary"
              >
                {{ item }}
              </a-typography-text>
            </div>
          </div>
        </a-col>
      </a-row>
      <a-row type="flex" justify="center">
        <a-col :span="8" class="p-relative">
          <a-row
            type="flex"
            justify="center"
            align="middle"
            style="
              min-height: 300px;
              height: 100%;
              width: 100%;
              top: 0;
              background: rgba(255, 255, 255, 0.5);
              z-index: 99;
            "
            class="p-absolute"
            v-show="loading"
          >
            <a-spin />
          </a-row>
          <div class="d-flex">
            <div
              class="py-10"
              style="width: 14%"
              v-for="(item, index) in daylist"
              :key="index"
            >
              <div v-if="!item"></div>
              <div v-else class="day" :class="{ 'bg-e0': item }">
                <div
                  :class="{
                    'bg-success':
                      item.dayPart == 'FULL' || item.dayPart == 'AM',
                    edithover: edit,
                  }"
                ></div>
                <div
                  :class="{
                    'bg-success':
                      item.dayPart == 'FULL' || item.dayPart == 'PM',
                    edithover: edit,
                  }"
                ></div>
                <span> {{ item.day }} </span>
                <div class="handle" v-if="edit">
                  <div class="edithover" @click="saveSubmit(index, 'AM')"></div>
                  <div class="edithover" @click="saveSubmit(index, 'PM')"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-30">
            <a-row type="flex" justify="center">
              <a-col>
                <a-button
                  size="large"
                  type="primary"
                  @click="switchEdit"
                  v-if="!edit"
                  >Edit</a-button
                >
                <a-button size="large" @click="switchEdit" v-else>OK</a-button>
              </a-col>
            </a-row>
          </div>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  getCurrentInstance,
  computed,
  watch,
  inject,
} from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

import { LeftOutlined, RightOutlined } from "@ant-design/icons-vue";
const week = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const fullday = [1, 3, 5, 7, 8, 10, 12];

export default {
  components: {
    LeftOutlined,
    RightOutlined,
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const curRoute = computed(() => route.path);
    const isLogin = computed(() => store.state.isLogin);

    const v = reactive({
      week,
      daylist: [],
      workdays: [],
      currentTime: "",
      loading: false,
      edit: false,
    });

    watch(
      () => v.currentTime,
      () => {
        getworkdays();
      }
    );

    v.currentTime = new Date().getTime();

    async function getworkdays() {
      v.loading = true;
      const res = await proxy.$axios.get("/api/v1/workdays", {
        month: proxy.$filters.formatTime(v.currentTime, "YYYYMM"),
      });
      if (res.data) {
        v.workdays = res.data;

        getdaylist(v.currentTime);
      }
    }

    // getworkdays();

    function getdaylist(time) {
      let list = [];
      let oneday =
        time - (proxy.$filters.getDayofMonth(time) - 1) * (86400 * 1000);
      let offset = proxy.$filters.getDayofWeek(oneday);
      
      if (offset == 0) offset = 7;

      for (let i = 1; i < offset; i++) {
        list.push(null);
      }

      let month = proxy.$filters.getMonthofYear(time);
      let length = 30;

      if (month == 1) {
        length = proxy.$filters.isLeapYear(time) ? 29 : 28;
      } else if (fullday.some((e) => e == month + 1)) {
        length = 31;
      }

      for (let i = 1; i <= length; i++) {
        let date = proxy.$filters.formatTime(
          oneday + 86400000 * (i - 1),
          "YYYYMMDD"
        );
        let target = v.workdays.find((item) => item.date == date);

        list.push({ day: i, date, dayPart: target ? target.dayPart : "OFF" });
      }

      v.daylist = list;
      v.loading = false;
    }

    function togglemonth(type) {
      if (type) {
        v.currentTime += 86400000 * 30;
      } else {
        v.currentTime = v.currentTime - 86400000 * 30;
      }
    }

    async function saveSubmit(index, part) {
      if (!v.edit) return;
      let obj = { dayPart: null };
      let dayPart = v.daylist[index].dayPart;

      if (dayPart == "FULL") {
        obj.dayPart = part == "AM" ? "PM" : "AM";
      } else if (dayPart == "AM") {
        obj.dayPart = part == "AM" ? "OFF" : "FULL";
      } else if (dayPart == "PM") {
        obj.dayPart = part == "AM" ? "FULL" : "OFF";
      } else if (dayPart == "OFF") {
        obj.dayPart = part;
      }
      v.loading = true;
      try {
        const res = await proxy.$axios.put(
          `/api/v1/workdays/${v.daylist[index].date}`,
          obj
        );
        if (res.status == 200) {
          v.loading = false;
          v.daylist[index].dayPart = obj.dayPart;
          proxy.$msg.success("save success");
        }
      } catch (err) {
        v.loading = false;

        proxy.$msg.error("Editing failed, please try again later");
      }
    }

    function switchEdit() {
      v.edit = !v.edit;
      proxy.$msg.info(v.edit ? `Start editing` : "Edit complete");
    }

    async function reCalculateSalaries() {
      v.loading = true;

      const currentMonth = parseInt(
        proxy.$filters.formatTime(v.currentTime, "YYYYMM")
      );

      try {
        const res = await proxy.$axios.put(`/api/v1/salaries/calculate`, {
          month: currentMonth,
        });
        if (res.status == 200) {
          v.loading = false;
          proxy.$msg.success("Successful");
        }
      } catch (err) {
        v.loading = false;

        proxy.$msg.error("Failed to re-calculate salaries of this month!");
      }
    }

    return {
      ...toRefs(v),
      switchEdit,
      togglemonth,
      saveSubmit,
      reCalculateSalaries,
    };
  },
};
</script>

<style lang="scss" scoped>
.day {
  width: 50px;
  height: 50px;
  margin: auto;
  font-size: 18px;
  font-weight: 500;
  border-radius: 5px;
  color: #000;
  position: relative;
  overflow: hidden;

  -webkit-user-seletct: none;
  -moz-user-seletct: none;
  -ms-user-seletct: none;
  div {
    height: 50%;
  }
  .handle {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    cursor: pointer;
    div {
      height: 50%;
    }
  }
  .edithover {
    &:hover {
      box-shadow: 0 0 3px 1px #888;
    }
  }
  span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
